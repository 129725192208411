import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SharedModule} from './shared/shared.module';
import { MenuItems} from './shared/menu-items/menu-items';
import { BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';
import { DatePipe, CurrencyPipe, registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {ToastyModule} from 'ng2-toasty';

registerLocaleData(localeEs, 'es-ES');

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ToastyModule.forRoot()
  ],
  providers: [
    MenuItems,
    DatePipe,
    CurrencyPipe,
    {
      provide: LOCALE_ID,
      useValue: 'es-ES'
    }
  ],
  exports: [ToastyModule],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
