import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Menu',
    main: [
      {
        state: 'cashflow',
        short_label: 'C',
        name: 'Cash Flow Projection',
        type: 'link',
        icon: 'fa fa-area-chart',
      },
      {
        state: 'conciliacion',
        short_label: 'C',
        name: 'Conciliación',
        type: 'link',
        icon: 'fa fa-calendar-check-o'
      }
    ]
  }
];


@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
